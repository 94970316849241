export const environment = {
  firebase: {
    // Prod firebase
    apiKey: "AIzaSyD3Tmqw6XdmNREtPnyQe52GSiE234ytPns",
    authDomain: "solvay-iflexi-thailand-prod.firebaseapp.com",
    projectId: "solvay-iflexi-thailand-prod",
    storageBucket: "solvay-iflexi-thailand-prod.appspot.com",
    messagingSenderId: "193825575709",
    apiURL: "https://iflex-thailand-api.solvay.com/api/",
    nonApiURL: "https://iflex-thailand-api.solvay.com/"

  },
  production: true
};
